
  import { defineComponent, onMounted, ref, nextTick, reactive } from "vue";

  // import list from "@/components/common/list.vue";

  import MenuTab from "@/components/common/MenuTab.vue";
  import Toast from "@/components/UI/Toast/";
  import TopicItem from "@/components/common/TopicItem.vue";
  import productCard from "@/components/common/productCard.vue";
  import bnccProductCard from "@/components/common/bnccProductCard.vue";
  import DocItem from "@/components/common/DocItem.vue";
  //ts
  import {
    strainOrCellsMap,
    IstrainOrCellsList,
  } from "@/hooks/useProduct";

  //  utils
  import axios from "@/api/axios";
  import Loading from "@/components/UI/Loading/index";
  import Empty from "@/components/common/Empty.vue";

  export default defineComponent({
    name: "Collection",
    components: {
      MenuTab,
      TopicItem,
      bnccProductCard,
      Loading,
      Empty
    },

    setup() {
      //  切换列表菜单
      type Menu = "帖子"| "菌种"|"细胞";
      const menu: Menu[] = ["帖子","菌种","细胞"];
      const currentMenuItem = ref(menu[0]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      //   Data Source
      const tzlist = reactive<any[]>([]);
      const jzlist = reactive<object[]>([]);
      const xblist = reactive<object[]>([]);

      const loading = ref(true);
      axios
              .get("/M/User/CollectionNew")
              .then((res) => {
                if (res.data.success) {
                  const data = res.data.obj;
                  data.tzlist.forEach((item: any) => {
                    tzlist.push(item);
                  });
                  data.jzlist.forEach((item: IstrainOrCellsList) => {
                    jzlist.push(strainOrCellsMap(item));
                  });
                  data.xblist.forEach((item: IstrainOrCellsList) => {
                    xblist.push(strainOrCellsMap(item));
                  });

                  // data.wklist.forEach((item: any) => {
                  //   wklist.push(item);
                  // });
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
                loading.value = false;
              })
              .catch((err) => {
                loading.value = false;
                console.log('err')
              });
      const loadOption = {
        text: "加载中...",
        color: "#df0024",
        textColor: "#df0024",
      };
      return {
        menu,
        selectMenu,
        currentMenuItem,
        tzlist,
        jzlist,
        xblist,
        loading,
        loadOption,
      };
    },
  });
